import React from "react";
import { useTexture, useAspect, Float, Center } from "@react-three/drei";
import logoSvg from "../assets/logo_bi.png";

function Logo() {
  const texture = useTexture(logoSvg);

  const scale = useAspect(803.73, 221.03, 0.05);

  return (
    <>
      {/* @ts-ignore */}
      <Float
        speed={4} // Animation speed, defaults to 1
        rotationIntensity={0.2} // XYZ rotation intensity, defaults to 1
        floatIntensity={0.1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
        floatingRange={[-0.2, 0.2]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
        position={[0, 0, 2]}
      >
        {/* @ts-ignore */}
        <Center>
          <mesh scale={scale}>
            <planeGeometry />
            <meshBasicMaterial
              alphaMap={texture}
              transparent={true}
              opacity={0.75}
            />
          </mesh>
        </Center>
      </Float>
    </>
  );
}

export default Logo;
