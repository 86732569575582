import React from "react";
import { useVideoTexture, useAspect, Center } from "@react-three/drei";
import mp4Video from "../assets/video.mp4";

function Video() {
  const texture = useVideoTexture(mp4Video, {
    autoplay: true,
    loop: true,
    muted: true,
    playsInline: true,
  });

  const scale = useAspect(1920, 1080, 1);

  return (
    <>
      {/* @ts-ignore */}
      <Center>
        <mesh scale={scale}>
          <planeGeometry />
          <meshBasicMaterial map={texture} toneMapped={false} />
        </mesh>
      </Center>
    </>
  );
}

export default Video;
