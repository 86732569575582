import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { Glitch, EffectComposer, Noise } from "@react-three/postprocessing";
import { GlitchMode, BlendFunction } from "postprocessing";
import "./App.css";
import Logo from "./components/Logo";
import Video from "./components/Video";
import { Swiper, SwiperSlide } from "swiper/react";
import {Pagination, Autoplay, EffectCards} from 'swiper';

import "swiper/css";
import 'swiper/css/pagination';
import "swiper/css/effect-cards";

function App() {
  return (
    <>
      <div className="wrapper">
        <Canvas dpr={[1, 2]} camera={{ fov: 50 }}>
          <Suspense fallback={null}>
            <Video />
            <Logo />
            <EffectComposer>
              <Glitch
                delay={[0.6, 2.2]} // min and max glitch delay
                duration={[0.05, 0.2]} // min and max glitch duration
                strength={[0.2, 1.2]} // min and max glitch strength
                mode={GlitchMode.SPORADIC} // glitch mode
                active // turn on/off the effect (switches between "mode" prop and GlitchMode.DISABLED)
                ratio={0.2} // Threshold for strong glitches, 0 - no weak glitches, 1 - no strong glitches.
              />
              <Noise
                premultiply // enables or disables noise premultiplication
                blendFunction={BlendFunction.ADD} // blend mode
              />
            </EffectComposer>
          </Suspense>
        </Canvas>
      </div>
      <div className="content-wrapper">
        <div className="header">
          <div className="socialmedia">
            ☆{" "}
            <a
              href="https://www.instagram.com/qlash.at/"
              target="_blank"
              rel="noreferrer"
            >
              INSTAGRAM
            </a>{" "}
            ☆{" "}
            <a
              href="https://www.facebook.com/Qlash.at/"
              target="_blank"
              rel="noreferrer"
            >
              FACEBOOK
            </a>{" "}
            ☆{" "}
            <a
              href="https://www.tiktok.com/@qlash.at"
              target="_blank"
              rel="noreferrer"
            >
              TIKTOK
            </a>
          </div>
        </div>
        <div className="footer">
          <div className="left comingup">
            <Swiper
              modules={[Pagination, Autoplay, EffectCards]}
              pagination={{ clickable: true }}
              loop
              autoplay={{ delay: 5000 }}
              effect={"cards"}
            >
              <SwiperSlide>
                <h2>
                  <time dateTime="2023-10-28 20:00">28.10.2023 20:00</time>
                  BIRTHDAY QLASH @ STWST
                </h2>
                <ul className="lineup">
                  <li>COUSINES LIKE SHIT</li>
                  <li>COLOR THE NIGHT</li>
                  <li>ANDA MORTS</li>
                  <li>TAUCHEN</li>
                </ul>
                <span>
                  <a
                    href="https://kupfticket.com/en/events/birthday-qlash-15-jahre-kulturverein-junqat"
                    target="_blank"
                    rel="noreferrer"
                  >
                    INFOS & TICKETS
                  </a>
                </span>
              </SwiperSlide>
              <SwiperSlide>
                <h2>
                  <time dateTime="2023-11-11 20:00">11.11.2023 20:00</time>
                  TRASH QLASH @ Florentine
                </h2>
                <ul className="lineup">
                  <li>- TBA -</li>
                </ul>
              </SwiperSlide>
              <SwiperSlide>
                <h2>
                  <time dateTime="2023-11-24 20:00">24.11.2023 21:00</time>
                  Ozymandias Releaseshow @ KAPU
                </h2>
                <ul className="lineup">
                  <li>OZYMANDIAS (Linz, AT)</li>
                  <li>ACID ROW (Prag, CZ)</li>
                  <li>GREAT RIFT (Wien, AT)</li>
                </ul>
                <span>
                  <a
                    href="https://kupfticket.com/en/events/heavy-rock-qlash-ozymandias-acid-row-great-rift"
                    target="_blank"
                    rel="noreferrer"
                  >
                    INFOS & TICKETS
                  </a>
                </span>
              </SwiperSlide>
              <SwiperSlide>
                <h2>
                  <time>MORE TO COME</time>
                  JOIN OUR NEWSLETTER
                </h2>
                <span>
                  <a
                    href="https://forms.gle/XwjVtVREek4AG2c36"
                    target="_blank"
                    rel="noreferrer"
                  >
                    SIGN UP NOW
                  </a>
                </span>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="right">
            <a href="https://junq.at/kontakt" target="_blank" rel="noreferrer">
              Impressum
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
